import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  DownOutlined,
  MinusCircleOutlined,
  PrinterOutlined,
  SaveOutlined,
  UserOutlined,
} from '@ant-design/icons';
import {
  Button,
  Dropdown,
  Table,
  Input,
  message,
  Menu,
  Space,
  Switch,
  DatePicker,
  Checkbox,
  Tooltip,
  Tag,
} from 'antd';
import moment from 'moment';
import axios from 'axios';
import { authContext } from '../../ProvideAuth';
import { FormattedUSD } from '../FormattedUSD';
import { jsPDF } from 'jspdf';
import { formatDateSpanishLong } from '../../utils';
import { calculateISRforThisPayroll } from '../../utils';

const calculateNextReasonablePayableDate = () => {
  const today = new Date();
  const currentDay = today.getDate();
  const currentMonth = today.getMonth();
  const currentYear = today.getFullYear();

  // Calculate the 15th of the current month
  let payableDate = new Date(currentYear, currentMonth, 15);

  // Check if the 15th is a Sunday, adjust to the 14th if necessary
  if (payableDate.getDay() === 0) {
    payableDate.setDate(14);
  }

  // If today is past the 15th, set the payable date to the 30th of the month
  if (currentDay >= 15) {
    payableDate = new Date(currentYear, currentMonth, 30);
    // Adjust for months with less than 31 days
    if (payableDate.getDate() !== 30) {
      payableDate.setDate(0); // Sets to the last day of the current month
    }
  }

  let formattedDate = moment(payableDate); // Use moment directly on the Date object
  return formattedDate;
};

function NewPayrollTable(props) {
  const auth = useContext(authContext);
  const [payrollData, setPayrollData] = useState([]);
  const [isCondensed, setIsCondensed] = useState(true);
  const [payableDate, setPayableDate] = useState(
    calculateNextReasonablePayableDate
  );
  const [employeeData, setEmployeeData] = useState([]); // this could live in EmployeesView (instead of EmployeesTable) and be passed down all over the place..
  const [decimoTercerMesBoolean, setDecimoTercerMesBoolean] = useState(false);
  const [vacacionesBoolean, setVacacionesBoolean] = useState(false);
  const [tableLoading, setTableLoading] = useState(true);

  function toggleView() {
    setIsCondensed(!isCondensed);
  }

  const handleCreatePayroll = async () => {
    const payrollDataWithPayableDate = payrollData.map((emp) => {
      return {
        ...emp,
        accounting_clients_id: props.selectedAccountingClientId,
        payable_date: payableDate,
        comments: decimoTercerMesBoolean
          ? 'DECIMO'
          : vacacionesBoolean
          ? 'VACACIONES'
          : '',
        employee_id: emp.employee_id,
      };
    });
    // console.log('payrollDataWithPayableDate:', payrollDataWithPayableDate);
    try {
      if (!payableDate) {
        message.error('Por favor seleccione una fecha de pago');
        return;
      }
      const response = await axios.post(
        `${props.API_domain}createPayroll`,
        { payrollDataWithPayableDate },
        { auth: { username: auth.email, password: auth.token } }
      );
      message.success('Planilla creada exitosamente');
      props.onCancel();
      props.setSelectedPayroll(response.data.selectedDate);
      props.setSendApprovedPayroll(false); // change to false to avoid sending the payroll after approval
      props.setPayrollModalContent('old');
    } catch (error) {
      console.error('Error creating payroll:', error);
    }
  };

  const handleSendForApproval = async () => {
    const payrollPDF = await exportPDF(
      'payrollTable',
      payableDate + '_borrador_de_planilla' + '.pdf',
      true // Output only the PDF blob
    );
    const new_payroll_ids = await handleSavePayrollDraft();
    // console.log('new_payroll_ids:', new_payroll_ids);
    const formData = new FormData();
    formData.append(
      'file',
      payrollPDF,
      `${payableDate}_borrador_de_planilla.pdf`
    );
    formData.append('new_payroll_ids', JSON.stringify(new_payroll_ids));
    formData.append('payable_date', payableDate);
    formData.append('accounting_client_id', props.selectedAccountingClientId);
    axios({
      method: 'post',
      url: props.API_domain + 'sendPayrollDraftForApproval',
      data: formData,
      auth: {
        username: auth.email,
        password: auth.token,
      },
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      // then message success
    })
      .then((response) => {
        // the backend will respond a list of email recipients. let's show them in a message success
        message.success(
          <>
            Planilla y comprobantes enviados a:{' '}
            <b>{response.data.join(', ')}.</b>
          </>,
          15
        );
      })
      .catch((error) => {
        console.error('Error:', error);
      });
    return;
  };

  const handleSavePayrollDraft = async () => {
    const payrollDataWithPayableDate = payrollData.map((emp) => {
      return {
        ...emp,
        accounting_clients_id: props.selectedAccountingClientId,
        payable_date: payableDate,
        comments: decimoTercerMesBoolean ? 'DECIMO' : '',
        employee_id: emp.employee_id,
      };
    });
    if (!payableDate) {
      message.error('Por favor seleccione una fecha de pago');
      return;
    }
    try {
      const response = await axios.post(
        `${props.API_domain}savePayrollDraft`,
        { payrollDataWithPayableDate },
        { auth: { username: auth.email, password: auth.token } }
      );
      message.success('Planilla guardada exitosamente');
      return response.data.new_payroll_ids;
    } catch (error) {
      message.error('Error guardando la planilla');
      console.error('Error saving payroll draft:', error);
    }
    message.success('Planilla guardada exitosamente');
  };

  useEffect(() => {
    if (employeeData.length === 0) return;

    const fetchCurrentPayroll = async () => {
      setTableLoading(true);
      try {
        const response = await axios.post(
          `${props.API_domain}getPayroll`,
          { accountingClientsId: props.selectedAccountingClientId },
          { auth: { username: auth.email, password: auth.token } }
        );

        if (response.data && Array.isArray(response.data.payroll)) {
          const formattedData = response.data.payroll.map((emp) => {
            let updatedEmp = {
              ...emp,
              biweekly_salary: parseFloat(emp.biweekly_salary) || 0,
              hasDeductions: true,
            };
            const employee = employeeData.find(
              (empData) => empData.employee_id === updatedEmp.employee_id
            );
            updatedEmp = calculatePayrollComponents(updatedEmp, employee);
            return {
              ...updatedEmp,
              key: emp.id,
            };
          });
          // console.log('Fetched payroll data:', formattedData);
          setPayrollData(formattedData);
          setTableLoading(false);
          // console.log('Payroll data:', formattedData);
        }
      } catch (error) {
        setTableLoading(false);
        console.error('Error fetching current payroll:', error);
      }
    };

    fetchCurrentPayroll();
  }, [employeeData, props.selectedAccountingClientId]);

  const calculatePayrollComponents = (
    updatedItem,
    employee,
    isr_changed = false
  ) => {
    updatedItem.biweekly_salary = updatedItem.biweekly_salary || 0;
    updatedItem.advance_deductions = updatedItem.advance_deductions || 0; // Ensure advance_deductions is initialized

    updatedItem.payroll_gross =
      parseFloat(updatedItem.biweekly_salary) +
      parseFloat(updatedItem.benefit_production) +
      parseFloat(updatedItem.benefit_fuel) +
      parseFloat(updatedItem.benefit_representation_expenses) +
      parseFloat(updatedItem.overtime);

    const productionBenefitSS =
      parseFloat(updatedItem.benefit_production) -
        updatedItem.biweekly_salary * 0.5 >
      0
        ? parseFloat(updatedItem.benefit_production) -
          updatedItem.biweekly_salary * 0.5
        : 0;
    updatedItem.benefit_production_subject_to_social_security =
      productionBenefitSS;

    const fuelBenefitSS =
      parseFloat(updatedItem.benefit_fuel) -
        (parseFloat(productionBenefitSS) +
          parseFloat(updatedItem.biweekly_salary)) *
          0.2 >
      0
        ? parseFloat(updatedItem.benefit_fuel) -
          (parseFloat(productionBenefitSS) +
            parseFloat(updatedItem.biweekly_salary)) *
            0.2
        : 0;
    updatedItem.benefit_fuel_subject_to_social_security = fuelBenefitSS;

    updatedItem.payroll_subject_to_ss =
      updatedItem.biweekly_salary +
      updatedItem.benefit_representation_expenses +
      updatedItem.overtime +
      productionBenefitSS +
      fuelBenefitSS -
      parseFloat(updatedItem.deduction_attendance);

    updatedItem.deduction_ss =
      updatedItem.payroll_subject_to_ss *
      (decimoTercerMesBoolean ? 0.0725 : 0.0975);

    updatedItem.payroll_subject_to_se =
      updatedItem.biweekly_salary +
      updatedItem.overtime +
      parseFloat(updatedItem.benefit_fuel) -
      parseFloat(updatedItem.deduction_attendance);

    if (decimoTercerMesBoolean) {
      updatedItem.payroll_subject_to_se = 0;
    }

    updatedItem.deduction_se = updatedItem.payroll_subject_to_se * 0.0125;

    if (!isr_changed) {
      const total_comp_subject_to_isr_this_year_so_far =
        employee.total_comp_subject_to_isr_this_year_so_far || 0;
      const total_isr_deducted_this_year_so_far =
        employee.total_isr_deducted_this_year_so_far || 0;
      let isrDetails = calculateISRforThisPayroll(
        total_comp_subject_to_isr_this_year_so_far,
        total_isr_deducted_this_year_so_far,
        payableDate,
        updatedItem.payroll_gross,
        updatedItem.payroll_subject_to_ss,
        updatedItem.benefit_representation_expenses
      );

      updatedItem.deduction_isr = isrDetails.isrForThisPayroll;
      updatedItem.isrDetails = isrDetails;
    }

    updatedItem.deduction_total =
      updatedItem.deduction_ss +
      updatedItem.deduction_se +
      updatedItem.deduction_isr +
      updatedItem.deduction_direct_discount +
      updatedItem.advance_deductions + // Include advance_deductions in total deductions
      parseFloat(updatedItem.deduction_attendance);

    updatedItem.payroll_net =
      updatedItem.payroll_gross - updatedItem.deduction_total;

    if (!updatedItem.hasDeductions) {
      updatedItem.payroll_subject_to_se = 0;
      updatedItem.payroll_subject_to_ss = 0;
      updatedItem.deduction_ss = 0;
      updatedItem.deduction_se = 0;
      updatedItem.deduction_isr = 0;
      updatedItem.deduction_total =
        updatedItem.deduction_attendance +
        updatedItem.advance_deductions + // Include advance_deductions in total deductions
        updatedItem.deduction_direct_discount;
      updatedItem.payroll_net =
        updatedItem.payroll_gross -
        updatedItem.deduction_attendance -
        updatedItem.advance_deductions -
        updatedItem.deduction_direct_discount;
    }
    return updatedItem;
  };

  const handleDecimoTercerMesChange = (e) => {
    const newDecimoTercerMesBoolean = e.target.checked;
    setDecimoTercerMesBoolean(newDecimoTercerMesBoolean);
  };

  useEffect(() => {
    // calculatePayrollComponent for all employees when decimoTercerMesBoolean changes
    const updatedPayrollData = payrollData.map((item) => {
      const employee = employeeData.find(
        (emp) => emp.employee_id === item.employee_id
      );
      return calculatePayrollComponents(item, employee, true);
    });
    setPayrollData(updatedPayrollData);
  }, [decimoTercerMesBoolean]);

  const handleEditChange = (value, key, column) => {
    const newData = payrollData.map((item) => {
      if (item.key === key) {
        const updatedValue = value !== '' ? parseFloat(value) : parseFloat(0); // Ensure empty values are set to 0
        const updatedItem = { ...item, [column]: updatedValue };
        const employee = employeeData.find(
          (emp) => emp.employee_id == item.employee_id
        );
        return calculatePayrollComponents(
          updatedItem,
          employee,
          column === 'deduction_isr'
        );
      }
      return item;
    });

    setPayrollData(newData);
  };

  const handleCheckboxChange = (e, key) => {
    const { checked } = e.target;
    const newData = payrollData.map((item) => {
      if (item.key === key) {
        const employee = employeeData.find(
          (emp) => emp.employee_id == item.employee_id
        );
        const updatedItem = { ...item, hasDeductions: checked };
        return calculatePayrollComponents(updatedItem, employee);
      }
      return item;
    });
    setPayrollData(newData);
  };

  const columns = [
    {
      title: 'Persona ',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => (
        <>
          <div>
            <b>{record.name}</b>
          </div>
          <div>{record.gov_id}</div>
        </>
      ),
    },
    {
      title: 'Aplicar deducciones',
      dataIndex: 'hasDeductions',
      key: 'hasDeductions',
      width: 80,
      render: (text, record) => (
        <Checkbox
          style={{ marginLeft: 20 }}
          checked={record.hasDeductions}
          onChange={(e) => handleCheckboxChange(e, record.key)}
        />
      ),
    },
    {
      title: 'Salario Quincenal',
      dataIndex: 'biweekly_salary',
      key: 'biweekly_salary',
      editable: true,
    },
    {
      title: 'Prima de Prod.',
      dataIndex: 'benefit_production',
      key: 'benefit_production',
      editable: true,
    },
    {
      title: 'Prima de Comb.',
      dataIndex: 'benefit_fuel',
      key: 'benefit_fuel',
      editable: true,
    },
    {
      title: 'Gastos de Rep.',
      dataIndex: 'benefit_representation_expenses',
      key: 'benefit_representation_expenses',
      editable: true,
    },
    {
      title: 'Tiempo Extra',
      dataIndex: 'overtime',
      key: 'overtime',
      editable: true,
    },
    {
      title: 'Total de Ingresos',
      dataIndex: 'payroll_gross',
      key: 'payroll_gross',
      render: (text) => (
        <b style={{ color: 'green' }}>
          <FormattedUSD total={parseFloat(text).toFixed(2)} />
        </b>
      ),
    },
    {
      title: 'Prima de Prod. sujeta a SS',
      dataIndex: 'benefit_production_subject_to_social_security',
      key: 'benefit_production_subject_to_social_security',
      render: (text) => <FormattedUSD total={parseFloat(text).toFixed(2)} />,
    },
    {
      title: 'Prima de Comb. sujeta a SS',
      dataIndex: 'benefit_fuel_subject_to_social_security',
      key: 'benefit_fuel_subject_to_social_security',
      render: (text) => <FormattedUSD total={parseFloat(text).toFixed(2)} />,
    },
    {
      title: 'Menos Ausencias',
      dataIndex: 'deduction_attendance',
      key: 'deduction_attendance',
      editable: true,
      render: (text) => <FormattedUSD total={parseFloat(text).toFixed(2)} />,
    },
    {
      title: 'Ingresos sujetos a SS',
      dataIndex: 'payroll_subject_to_ss',
      key: 'payroll_subject_to_ss',
      render: (text) => <FormattedUSD total={parseFloat(text).toFixed(2)} />,
    },
    {
      title: decimoTercerMesBoolean
        ? 'Seguro Social 7.25%'
        : 'Seguro Social 9.75%',
      dataIndex: 'deduction_ss',
      key: 'deduction_ss',
      render: (text) => <FormattedUSD total={parseFloat(text).toFixed(2)} />,
    },
    {
      title: 'Ingresos sujetos a SE',
      dataIndex: 'payroll_subject_to_se',
      key: 'payroll_subject_to_se',
      render: (text) => <FormattedUSD total={parseFloat(text).toFixed(2)} />,
    },
    {
      title: 'Seguro Educativo 1.25%',
      dataIndex: 'deduction_se',
      key: 'deduction_se',
      render: (text) => <FormattedUSD total={parseFloat(text).toFixed(2)} />,
    },
    {
      title: 'Descuento Directo',
      dataIndex: 'deduction_direct_discount',
      key: 'deduction_direct_discount',
      editable: true,
      render: (text) => <FormattedUSD total={parseFloat(text).toFixed(2)} />,
    },
    {
      title: 'ISR',
      dataIndex: 'deduction_isr',
      key: 'deduction_isr',
      editable: true,
      render: (text) => <FormattedUSD total={parseFloat(text).toFixed(2)} />,
    },
    {
      title: 'Descuento de Adelanto',
      dataIndex: 'advance_deductions', // Added advance_deductions
      key: 'advance_deductions',
      editable: true,
      render: (text) => <FormattedUSD total={parseFloat(text).toFixed(2)} />,
    },
    {
      title: 'Adelantos pendientes por descontar',
      dataIndex: 'pending_deductions',
      key: 'pending_deductions',
      render: (pending_deductions, record) => {
        const isZero = record.pending_deductions === 0;
        const isAdvanceLarger =
          record.advance_deductions > record.pending_deductions;
        return isZero && isAdvanceLarger ? (
          <span style={{ color: 'red', fontSize: '24px', fontWeight: 'bold' }}>
            $0.00
          </span>
        ) : isAdvanceLarger ? (
          <Tag color='red' style={{ fontSize: '24px', padding: 10 }}>
            <FormattedUSD total={record.pending_deductions} />
          </Tag>
        ) : record.pending_deductions > 0 ? (
          <div style={{ marginTop: 8 }}>
            <Tooltip
              title='Adelantos pendientes por descontar'
              placement='bottomLeft'
            >
              <Tag color='green' style={{ padding: 5 }}>
                <FormattedUSD total={record.pending_deductions} />
              </Tag>
            </Tooltip>
          </div>
        ) : (
          <span>$0.00</span>
        );
      },
    },
    {
      title: 'Total de Deducciones',
      key: 'deduction_total',
      render: (_, record) => (
        <b style={{ color: 'red' }}>
          <FormattedUSD total={parseFloat(record.deduction_total).toFixed(2)} />
        </b>
      ),
    },
    {
      title: 'Salario Neto a Recibir',
      dataIndex: 'payroll_net',
      key: 'payroll_net',
      render: (text) => (
        <b style={{ color: 'rgb(65,130,239)' }}>
          <FormattedUSD total={parseFloat(text).toFixed(2)} />
        </b>
      ),
    },
    {
      title: 'Notas',
      dataIndex: 'notes',
      key: 'notes',
      // editable: true,
      render: (text, record) => (
        <Input
          placeholder={text ? text : 'Ejemplo: Días trabajados 12-18'}
          style={{ width: 240 }}
          defaultValue={record.notes}
          onBlur={(e) => {
            const newData = payrollData.map((item) => {
              if (item.key === record.key) {
                return { ...item, notes: e.target.value };
              }
              return item;
            });
            setPayrollData(newData);
          }}
        />
      ),
    },
    {
      title: 'Acciones',
      key: 'actions',
      render: (_, record) => (
        <Space>
          <Button
            type='secondary'
            style={{ border: 'none' }}
            onClick={() => {
              setPayrollData(
                payrollData.filter((emp) => emp.key !== record.key)
              );
            }}
          >
            <MinusCircleOutlined />
          </Button>
        </Space>
      ),
    },
  ];

  const EditableCell = ({
    editable,
    dataIndex,
    record,
    handleEditChange,
    isrDetails,
    children,
    ...restProps
  }) => {
    const [inputValue, setInputValue] = useState(
      record && record[dataIndex] ? record[dataIndex] : ''
    );
    const inputRef = useRef(null);

    useEffect(() => {
      if (record && record[dataIndex] !== undefined) {
        setInputValue(record[dataIndex]);
      }
    }, [record, dataIndex]);

    const handleBlur = () => {
      if (record) {
        handleEditChange(inputValue, record.key, dataIndex);
      }
    };

    const handleFocus = () => {
      if (inputRef.current) {
        inputRef.current.select();
      }
    };

    const formatISRDetails = (details) => {
      const labels = {
        isrForThisPayroll: 'ISR para esta nómina',
        estimatedTotalCompensationThisYear:
          'Compensación total estimada este año',
        estimatedTotalAnnualISR: 'ISR total anual estimado',
        totalRemainingISR: 'ISR total restante',
        remainingPayrollsThisYear: 'Nóminas restantes este año',
        total_isr_deducted_this_year_so_far:
          'ISR total deducido este año hasta antes de esta planilla',
        total_comp_subject_to_isr_this_year_so_far:
          'Compensación total sujeta a ISR este año hasta antes de esta planilla',
        isrForThisPayrollFromRepresentationExpenses:
          'ISR para esta nómina de Gastos de Representación',
      };

      return (
        <>
          <h3 style={{ color: 'white' }}>Cómo se calcula el ISR?</h3>
          <table style={{ width: '100%', borderCollapse: 'collapse' }}>
            <tbody>
              {Object.entries(details).map(([key, value]) => {
                return (
                  <tr
                    key={key}
                    style={{
                      // borderBottom: '0.4px dashed white',
                      padding: '8px',
                    }}
                  >
                    <td
                      style={{
                        padding: '8px',
                        fontWeight:
                          key === 'isrForThisPayroll' ? 'bold' : 'normal',
                        fontSize: key === 'isrForThisPayroll' ? '16px' : '14px',
                      }}
                    >
                      {labels[key]}:
                    </td>
                    <td
                      style={{
                        padding: '8px',
                        textAlign: 'right',
                        fontWeight:
                          key === 'isrForThisPayroll' ? 'bold' : 'normal',
                        fontSize: key === 'isrForThisPayroll' ? '16px' : '14px',
                      }}
                    >
                      {key === 'remainingPayrollsThisYear' ? (
                        details[key]
                      ) : (
                        <FormattedUSD total={details[key]} />
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </>
      );
    };

    return (
      <td {...restProps}>
        {editable ? (
          dataIndex === 'deduction_isr' ? (
            <Tooltip
              overlayStyle={{ maxWidth: '400px' }}
              title={
                <div style={{ maxWidth: '100%' }}>
                  {formatISRDetails(isrDetails)}
                </div>
              }
              placement='topLeft'
            >
              <Input
                ref={inputRef}
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                onBlur={handleBlur}
                onFocus={handleFocus}
                style={{ padding: 4, width: 75, textAlign: 'center' }}
              />
            </Tooltip>
          ) : (
            <Input
              ref={inputRef}
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              onBlur={handleBlur}
              onFocus={handleFocus}
              style={{ padding: 4, width: 75, textAlign: 'center' }}
            />
          )
        ) : (
          children
        )}
      </td>
    );
  };

  const filteredColumns = columns.filter((col) => {
    if (isCondensed) {
      return [
        'name',
        'hasDeductions',
        'biweekly_salary',
        'benefit_production',
        'benefit_fuel',
        'overtime',
        'payroll_gross',
        'deduction_attendance',
        'advance_deductions',
        'deduction_total',
        'payroll_net',
        'notes',
        'actions',
      ].includes(col.key);
    }
    return true;
  });

  const mergedColumns = filteredColumns.map((col) => ({
    ...col,
    onCell: (record) => ({
      record,
      editable: col.editable,
      dataIndex: col.dataIndex,
      handleEditChange,
      isrDetails: record.isrDetails, // Pass ISR details here
    }),
  }));

  const exportPDF = async (
    element,
    file_name = 'download_hero.pdf',
    outputOnlyBlob = false
  ) => {
    const doc = new jsPDF('landscape', 'pt', 'A4');
    doc.setFontSize(18);

    const title =
      'Borrador de planilla' +
      ' del ' +
      formatDateSpanishLong(payableDate) +
      (decimoTercerMesBoolean ? ' - DECIMO' : '') +
      (vacacionesBoolean ? ' - Vacaciones' : '');
    doc.text(title, 40, 70);

    const logo1 = new Image();
    logo1.src = `accounting_client_logo_${props.selectedAccountingClientId}.png`;
    const logo2 = new Image();
    logo2.src = 'heroLogoSmall.png';

    const loadImageBase64 = (base64, mimeType = 'image/png') => {
      return new Promise((resolve) => {
        const img = new Image();
        img.onload = () => resolve(img);
        img.onerror = () => {
          console.warn(`Failed to load image`);
          resolve(null); // Resolve with null if image fails to load
        };
        // Set the src with the appropriate base64 data URL format
        img.src = `data:${mimeType};base64,${base64}`;
      });
    };
    const loadImage = (src) => {
      return new Promise((resolve) => {
        const img = new Image();
        img.onload = () => resolve(img);
        img.onerror = () => {
          console.warn(`Failed to load image: ${src}`);
          resolve(null); // Resolve with null if image fails to load
        };
        img.src = src;
      });
    };

    const generatePDF = async () => {
      const [loadedLogo1, loadedLogo2] = await Promise.all([
        loadImage('heroLogoSmall.png'),
        loadImageBase64(props.logoBase64),
      ]);

      const pageWidth = doc.internal.pageSize.getWidth();
      const yOffset = 20;
      const logoHeight = 50;
      let logoWidth1 = 0;
      let logoWidth2 = 0;
      let xOffset1 = pageWidth - 30; // Start from the right margin
      let xOffset2 = xOffset1;

      if (loadedLogo1) {
        const aspectRatio1 = loadedLogo1.width / loadedLogo1.height;
        logoWidth1 = logoHeight * aspectRatio1;
        xOffset1 -= logoWidth1;
      }

      if (loadedLogo2) {
        const aspectRatio2 = loadedLogo2.width / loadedLogo2.height;
        logoWidth2 = logoHeight * aspectRatio2;
        xOffset2 = xOffset1 - logoWidth2 - 10; // Adjust spacing between logos
      }

      // Add images if they are loaded
      if (loadedLogo1) {
        doc.addImage(
          loadedLogo1.src,
          'PNG',
          xOffset1,
          yOffset,
          logoWidth1,
          logoHeight
        );
      }

      if (loadedLogo2) {
        doc.addImage(
          loadedLogo2.src,
          'PNG',
          xOffset2,
          yOffset,
          logoWidth2,
          logoHeight
        );
      }

      const tableElement = document.getElementById(element);

      // Columns to exclude in all cases
      const excludeColumns = [
        'Aplicar deducciones',
        'Acciones',
        'Adelantos pendientes por descontar',
      ];

      // Filter columns based on isCondensed and always exclude specific columns
      const filteredColumns = columns.filter((col) => {
        if (isCondensed) {
          return [
            'name',
            'hasDeductions',
            'biweekly_salary',
            'benefit_production',
            'benefit_fuel',
            'overtime',
            'payroll_gross',
            'deduction_attendance',
            'advance_deductions',
            'deduction_total',
            'payroll_net',
            'notes',
          ].includes(col.key);
        }
        return !excludeColumns.includes(col.key);
      });

      // Filter table headers to exclude specific columns
      const tableHeaders = filteredColumns
        .map((col) => col.title)
        .filter((header) => !excludeColumns.includes(header));

      const tableRows = [];
      tableElement.querySelectorAll('tbody tr').forEach((row, rowIndex) => {
        const rowData = Array.from(row.querySelectorAll('td'))
          .map((td, colIndex) => {
            const columnTitle = filteredColumns[colIndex]?.title;
            const columnKey = filteredColumns[colIndex]?.dataIndex;
            let text = td.innerText;

            if (!columnTitle || excludeColumns.includes(columnTitle)) {
              return null;
            }

            // console.log('text:', text, 'columnTitle:', columnTitle, 'td:', td);
            // Check if columnKey is 'notes'
            if (columnKey == 'notes') {
              const inputElement = td.querySelector('input');
              if (inputElement) {
                text = inputElement.value; // Get the value of the input element
                // console.log('Input value for notes:', text);
              }
              return text;
            }

            let value = payrollData[rowIndex][columnKey] ?? '';
            if (text.trim() === '') {
              value = parseFloat(value).toFixed(2);
              if (value && !value.toString().includes('$')) {
                value = '$' + value;
              }
              return value;
            }
            if (columnKey === 'deduction_isr' && text.trim() !== '') {
              return parseFloat(text).toFixed(2);
            }
            return text;
          })
          .filter((cell) => cell !== null);
        tableRows.push(rowData);
      });

      const totals = tableHeaders.map((header, index) => {
        if (
          header !== 'Persona' &&
          header !== 'Fecha' &&
          index > 0 &&
          header !== 'Notas'
        ) {
          const total = tableRows
            .reduce((acc, row) => {
              let value = row[index];
              if (typeof value === 'string') {
                value = value.replace(/[^\d.-]/g, ''); // Remove non-numeric characters
              }
              return acc + (parseFloat(value) || 0);
            }, 0)
            .toFixed(2);
          return total === '0.00' ? '$0' : `$${total}`;
        }
        return '';
      });

      tableRows.push(totals);

      const tableStyles = {
        head: [tableHeaders],
        theme: 'plain',
        body: tableRows,
        startY: 100,
        styles: {
          fontSize: 10,
          cellPadding: { top: 5, right: 3, bottom: 5, left: 3 },
          overflow: 'linebreak',
          valign: 'middle',
          halign: 'center',
          fillColor: [255, 255, 255],
        },
        headStyles: {
          fillColor: [116, 116, 199],
          textColor: [255, 255, 255],
          fontSize: 8,
          halign: 'center',
        },
        bodyStyles: {
          halign: 'center',
          cellPadding: { top: 12, bottom: 12 },
        },
        columnStyles: {
          [tableHeaders.indexOf('Total de Ingresos')]: {
            textColor: [0, 128, 0],
            fontStyle: 'bold',
          },
          [tableHeaders.indexOf('Total de Deducciones')]: {
            textColor: [255, 0, 0],
            fontStyle: 'bold',
          },
          [tableHeaders.indexOf('Salario Neto a Recibir')]: {
            textColor: [65, 130, 239],
            fontStyle: 'bold',
          },
        },
        margin: { top: 150 },
      };

      doc.autoTable(tableStyles);
      const pdfBlob = doc.output('blob');

      return pdfBlob;
    };

    if (outputOnlyBlob) {
      return await generatePDF();
    } else {
      const pdfBlob = await generatePDF();
      doc.save(file_name);
      return pdfBlob; // Return PDF blob even if saving to disk
    }
  };

  useEffect(() => {
    const fetchEmployeeData = async () => {
      const selectedAccountingClientId = props.selectedAccountingClientId;
      if (!selectedAccountingClientId) {
        return;
      }
      try {
        const response = await axios.post(
          `${props.API_domain}getAccountingClientEmployees`,
          { accountingClientsId: selectedAccountingClientId },
          { auth: { username: auth.email, password: auth.token } }
        );
        // console.log('Employee data:', response.data);
        setEmployeeData(response.data);
      } catch (error) {
        console.error('Error fetching employee data:', error);
      }
    };

    fetchEmployeeData();
  }, [props.selectedAccountingClientId]);

  const handleAddPayrollRow = (e) => {
    const employeeId = e.key;
    // console.log('Adding Payroll Row for Employee:', employeeId);
    // console.log('Employee Data:', employeeData);
    const employee = employeeData.find((emp) => emp.employee_id == employeeId);
    // console.log(employee);
    const newPayrollRow = {
      employee_id: employeeId,
      key: payrollData.length + 1,
      name: employee.name,
      gov_id: employee.gov_id,
      biweekly_salary: 0,
      benefit_production: 0,
      benefit_fuel: 0,
      benefit_representation_expenses: 0,
      overtime: 0,
      payroll_gross: 0,
      benefit_production_subject_to_social_security: 0,
      benefit_fuel_subject_to_social_security: 0,
      deduction_attendance: 0,
      payroll_subject_to_ss: 0,
      deduction_ss: 0,
      payroll_subject_to_se: 0,
      deduction_se: 0,
      deduction_isr: 0,
      deduction_direct_discount: 0,
      pending_deductions: employee.pending_deductions || 0,
      advance_deductions: 0,
      deduction_total: 0,
      payroll_net: 0,
      hasDeductions: true,
      isrDetails: {}, // questionable!
    };

    setPayrollData([...payrollData, newPayrollRow]);
  };

  const menu = (
    <Menu onClick={handleAddPayrollRow}>
      {employeeData
        .filter((emp) => emp.end_date === null)
        .map((emp) => (
          <Menu.Item key={emp.employee_id}>
            <Space>
              <UserOutlined />
              {emp.name}
            </Space>
          </Menu.Item>
        ))}
    </Menu>
  );

  const getMostRecentEntries = (data) => {
    // Step 1: Sort the data by 'payable_date' in descending order
    const sortedData = data.sort(
      (a, b) => new Date(b.payable_date) - new Date(a.payable_date)
    );

    // Step 2: Select the 8 most recent entries
    const mostRecentEntries = sortedData.slice(0, 8);

    // Step 3: Format the output
    return {
      name: mostRecentEntries[0]?.name || '', // Get the name from the first entry, fallback to an empty string if the array is empty
      employeeId: mostRecentEntries[0]?.employee_id || '', // Get the employee_id from the first entry, fallback to an empty string if the array is empty
      entries: mostRecentEntries.map((entry) => ({
        gov_id: entry.gov_id,
        payroll_gross: entry.payroll_gross,
        payable_date: entry.payable_date,
      })),
    };
  };

  const getEmployeePaymentHistory = async (employeeId) => {
    if (!employeeId) return;

    try {
      const response = await axios.post(
        `${props.API_domain}getPayroll`,
        {
          accountingClientsId: props.selectedAccountingClientId,
          employeeId,
        },
        { auth: { username: auth.email, password: auth.token } }
      );

      if (response.data && Array.isArray(response.data.payroll)) {
        // add index to each record
        response.data.payroll.forEach((record, index) => {
          record.index = index + 1;
        });
        // setPayrollData(response.data.payroll); // Uncomment this to set the payroll data
        return getMostRecentEntries(response.data.payroll);
      }
    } catch (error) {
      console.error('Error fetching payroll data:', error);
      message.error(
        'Error al obtener historial de planillas. Posiblemente no tiene planillas.'
      );
    }
  };

  const calculateDecimoTercerMes = async (employeeData) => {
    let employeeIdsList = [];
    payrollData.forEach((emp) => {
      employeeIdsList.push(emp.employee_id);
    });
    let decimoTercerMesData = []; // should be a list of dictionaries. each dictionary shall have the name of the employee, the payable_date, and the biweekly salary from the last 8 payrolls
    // run getEmplpoyeePaymentHistory for each employee in order to populate decimoTercerMesData
    for (let i = 0; i < employeeIdsList.length; i++) {
      const employeeId = employeeIdsList[i];
      const response = await getEmployeePaymentHistory(employeeId);
      decimoTercerMesData.push(response);
    }

    // clean up
    setPayrollData([]);
    // populate payrollData with the data from decimoTercerMesData
    const updatedPayrollData = decimoTercerMesData.map((emp, index) => {
      return {
        employee_id: emp.employeeId,
        key: index + 1,
        name: emp.name,
        gov_id: emp.entries[0]?.gov_id || '',
        // biweekly_salary should be the sum of all of the biweekly salaries from the last 8 payrolls
        biweekly_salary:
          (8.333 / 100) *
          emp.entries.reduce(
            (acc, curr) => acc + curr.payroll_subject_to_ss,
            0
          ),
        // biweekly_salary: emp.entries[0]?.payroll_gross || 0,
        benefit_production: 0,
        benefit_fuel: 0,
        benefit_representation_expenses: 0,
        overtime: 0,
        payroll_gross: 0,
        benefit_production_subject_to_social_security: 0,
        benefit_fuel_subject_to_social_security: 0,
        deduction_attendance: 0,
        payroll_subject_to_ss: 0,
        deduction_ss: 0,
        payroll_subject_to_se: 0,
        deduction_se: 0,
        deduction_isr: 0,
        deduction_direct_discount: 0,
        pending_deductions: emp.pending_deductions || 0,
        advance_deductions: 0,
        deduction_total: 0,
        payroll_net: 0,
        hasDeductions: true,
        isrDetails: {},
        // lets include the last 8 payrolls in the notes
        notes: `${emp.entries
          .map((entry) => entry.payable_date + ' : ' + entry.payroll_gross)
          .join(' | ')}`,
      };
    });
    setPayrollData(updatedPayrollData);
    // to do: calculate other fields
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <div id='SelectDiv' style={{ flex: '0 1 auto', marginTop: 10 }}>
          <div>
            <span>Fecha a pagar:</span>
            <DatePicker
              style={{ marginLeft: 10 }}
              value={payableDate}
              onChange={(chosenDate) => {
                setPayableDate(chosenDate);
              }}
            />
          </div>
          <div>
            <Checkbox
              style={{ marginTop: 8 }}
              // onChange={(e) => setDecimoTercerMesBoolean(e.target.checked)}
              onChange={handleDecimoTercerMesChange}
            >
              Décimo Tercer Mes
            </Checkbox>
            <Checkbox
              style={{ marginTop: 8 }}
              onChange={(e) => setVacacionesBoolean(e.target.checked)}
            >
              Vacaciones
            </Checkbox>
          </div>
        </div>
        <div id='ActionsDiv' style={{ flex: '1 1 auto', textAlign: 'right' }}>
          <span style={{ marginRight: 8 }}>Vista simple:</span>
          <Switch checked={isCondensed} onChange={toggleView} />
          <Button
            type='secondary'
            onClick={() =>
              exportPDF('payrollTable', 'borrador_de_planilla' + '.pdf')
            }
            style={{ marginLeft: 10 }}
          >
            Exportar borrador a PDF
          </Button>
        </div>
      </div>
      <Table
        id='payrollTable'
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        columns={mergedColumns}
        dataSource={payrollData}
        rowClassName='editable-row'
        pagination={false}
        loading={tableLoading}
        footer={() => (
          <>
            <Dropdown overlay={menu}>
              <Button>
                <Space>
                  Agregar fila
                  <DownOutlined />
                </Space>
              </Button>
            </Dropdown>
            <Button
              style={{ marginLeft: 10 }}
              onClick={() => {
                message.info('Borrador limpiado');
                setPayrollData([]);
              }}
            >
              Limpiar borrador
            </Button>
            {decimoTercerMesBoolean && (
              <Button
                style={{ marginLeft: 10 }}
                onClick={() => {
                  message.info('Calculando Décimo Tercer Mes');
                  calculateDecimoTercerMes(employeeData);
                }}
              >
                Calcular Décimo Tercer Mes
              </Button>
            )}
            <div style={{ textAlign: 'right' }}>
              Total a Pagar:{' '}
              <b style={{ color: 'rgb(65,130,239)' }}>
                <FormattedUSD
                  total={payrollData.reduce(
                    (acc, curr) => acc + curr.payroll_net,
                    0
                  )}
                />
              </b>
            </div>
            {/* <div style={{ textAlign: 'right' }}>
              Total implicado para SIPE (hardcoded):{' '}
              <b style={{ color: 'grey' }}>
                <FormattedUSD
                  total={
                    0.1585 *
                    payrollData.reduce(
                      (acc, curr) => acc + curr.payroll_subject_to_ss,
                      0
                    )
                  }
                />
              </b>
            </div> */}
          </>
        )}
      />
      <div
        style={{
          width: '600px',
          margin: '0 auto',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Button
          type='secondary'
          style={{ width: '40%', margin: 10, height: 35 }}
          onClick={handleSavePayrollDraft}
        >
          <SaveOutlined />
          <b> Guardar </b> Borrador
        </Button>
        <Button
          type='secondary'
          style={{ margin: 10, height: 35 }}
          onClick={handleSendForApproval}
        >
          <SaveOutlined />
          <b> Enviar borrador </b> por correo pidiendo aprobación
        </Button>
        <Button
          type='primary'
          style={{ width: '60%', margin: 10, height: 35 }}
          onClick={handleCreatePayroll}
        >
          <PrinterOutlined />
          <b> Aprobar </b>Planilla
        </Button>
      </div>
    </>
  );
}

export { NewPayrollTable };
