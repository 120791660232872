import { Modal } from 'antd';
import { ExistingPayrollTableContainer } from './ExistingPayrollTableContainer';
import { NewPayrollTable } from './NewPayrollTable';
import axios from 'axios';
import { authContext } from '../../ProvideAuth';
import React, { useContext, useEffect, useState } from 'react';

function PayrollModal(props) {
  const auth = useContext(authContext);
  const [logoSrc, setLogoSrc] = useState(null);
  const [logoBase64, setLogoBase64] = useState(null);

  useEffect(() => {
    axios({
      method: 'post',
      url: props.API_domain + 'getAccountingClientLogoSource',
      auth: {
        username: auth.email,
        password: auth.token,
      },
      data: {
        selectedAccountingClientId: props.selectedAccountingClientId,
      },
    })
      .then(function (response) {
        setLogoSrc(response.data.logo_image_url);
        setLogoBase64(response.data.logo_image_base64);
      })
      .catch(function (response) {
        console.log(response);
      });
  }, [props.selectedAccountingClientId]);

  return (
    <Modal
      title={'Planilla'}
      visible={true}
      onCancel={props.onCancel}
      footer={null}
      width={'95%'}
    >
      {props.payrollModalContent == 'old' ? (
        <ExistingPayrollTableContainer
          API_domain={props.API_domain}
          selectedPayroll={props.selectedPayroll}
          selectedAccountingClientId={props.selectedAccountingClientId}
          sendApprovedPayroll={props.sendApprovedPayroll}
          setSendApprovedPayroll={props.setSendApprovedPayroll}
          onCancel={props.onCancel}
          logoSrc={logoSrc}
          logoBase64={logoBase64}
        />
      ) : (
        <NewPayrollTable
          API_domain={props.API_domain}
          selectedAccountingClientId={props.selectedAccountingClientId}
          setSelectedPayroll={props.setSelectedPayroll}
          onCancel={props.onCancel}
          setPayrollModalContent={props.setPayrollModalContent}
          setSendApprovedPayroll={props.setSendApprovedPayroll}
          logoSrc={logoSrc}
          logoBase64={logoBase64}
        />
      )}
    </Modal>
  );
}

export { PayrollModal };
