import { Col, Layout, message, Row, Button, Tooltip } from 'antd';
import {
  ContactsOutlined,
  OrderedListOutlined,
  UserAddOutlined,
} from '@ant-design/icons';
import { useState } from 'react';
import styled from 'styled-components/macro';
import { AccountingClientSelect } from '../Accounting/AccountingClientSelect.js';
import { EmployeesTable } from './EmployeesTable.js';
import { EmployeeModal } from './EmployeeModal.js';
import { PayrollModal } from './PayrollModal.js';
import { PayrollHistory } from './PayrollHistory.js';
import { Refresh } from '../Refresh.js';

const { Content } = Layout;

function EmployeesView(props) {
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [selectedPayroll, setSelectedPayroll] = useState(null);
  const [payrollModalContent, setPayrollModalContent] = useState(null);
  const [sendApprovedPayroll, setSendApprovedPayroll] = useState(false);
  const [selectedAccountingClientId, setSelectedAccountingClientId] =
    useState(null);
  const [employeeModalVisible, setEmployeeModalVisible] = useState(true);
  const employeeTypeColors = {
    honorarios_profesionales: 'magenta',
    css: 'volcano',
    mixto: 'blue',
  };
  return (
    <Content
      style={{
        margin: '0 0 0',
        overflow: 'initial',
        // borderLeft: 'solid rgb(235,235,235) 1px',
        borderRight: 'solid rgb(235,235,235) 1px',
        borderTop: 'solid rgb(235,235,235) 1px',
      }}
    >
      <AffixDiv>
        <Row>
          <Col span={14} style={{ textAlign: 'left' }}>
            <div
              style={{
                textAlign: 'left',
                paddingTop: 40,
                fontSize: 36,
                fontWeight: 'bold',
              }}
            >
              Empleados
            </div>
            <Refresh
              onClick={() => (
                setEmployeeModalVisible(!employeeModalVisible),
                setEmployeeModalVisible(!employeeModalVisible) // hacky
              )}
            />

            <AccountingClientSelect
              API_domain={props.API_domain}
              onAccountingClient={(accountingClient) => {
                setSelectedAccountingClientId(accountingClient);
              }}
            />
            <Tooltip
              title={
                selectedAccountingClientId
                  ? ''
                  : 'Selecciona una compañía antes'
              }
            >
              <Button
                type='secondary'
                onClick={() => (
                  setSelectedEmployee('new'), setEmployeeModalVisible(true)
                )}
                disabled={!selectedAccountingClientId}
              >
                <UserAddOutlined />
                Crear empleado
              </Button>
            </Tooltip>
          </Col>
          <Col span={10} style={{ textAlign: 'right' }}>
            <ButtonDiv type='primary'>
              <Tooltip
                title={
                  selectedAccountingClientId
                    ? ''
                    : 'Selecciona una compañía antes'
                }
              >
                <Button
                  type='primary'
                  onClick={() => setPayrollModalContent('new')}
                  disabled={
                    !selectedAccountingClientId
                    // || !(
                    //   auth.email == 'noreplyherofacturas@gmail.com' ||
                    //   auth.email == 'jarol@herofacturas.com'
                    // )
                  }
                >
                  <OrderedListOutlined />
                  Crear planilla
                </Button>
              </Tooltip>
            </ButtonDiv>
            <ButtonDiv>
              <Tooltip
                title={
                  selectedAccountingClientId
                    ? ''
                    : 'Selecciona una compañía antes'
                }
              >
                <Button
                  onClick={() => setPayrollModalContent('old')}
                  disabled={!selectedAccountingClientId}
                >
                  <ContactsOutlined />
                  Ver planillas anteriores
                </Button>
              </Tooltip>
            </ButtonDiv>
            <ButtonDiv>
              <Tooltip
                title={
                  selectedAccountingClientId
                    ? ''
                    : 'Selecciona una compañía antes'
                }
              >
                <PayrollHistory
                  API_domain={props.API_domain}
                  accounting_clients_id={selectedAccountingClientId}
                  disabled={!selectedAccountingClientId}
                />
              </Tooltip>
            </ButtonDiv>
          </Col>
        </Row>
        {selectedEmployee && (
          <EmployeeModal
            API_domain={props.API_domain}
            selectedEmployee={selectedEmployee}
            onCancel={() => setSelectedEmployee(null)}
            employeeTypeColors={employeeTypeColors}
            selectedAccountingClientId={selectedAccountingClientId}
            employeeModalVisible={employeeModalVisible}
            setEmployeeModalVisible={setEmployeeModalVisible}
          />
        )}
        {payrollModalContent && (
          <PayrollModal
            API_domain={props.API_domain}
            onCancel={() => {
              setPayrollModalContent(null);
              setSelectedPayroll(null);
            }}
            selectedAccountingClientId={selectedAccountingClientId}
            payrollModalContent={payrollModalContent}
            setPayrollModalContent={setPayrollModalContent}
            // setPayrollModalContent={setPayrollModalContent}
            selectedPayroll={selectedPayroll}
            setSelectedPayroll={setSelectedPayroll}
            sendApprovedPayroll={sendApprovedPayroll}
            setSendApprovedPayroll={setSendApprovedPayroll}
          />
        )}
        <EmployeesTable
          API_domain={props.API_domain}
          selectedEmployee={selectedEmployee}
          setSelectedEmployee={setSelectedEmployee}
          employeeTypeColors={employeeTypeColors}
          selectedAccountingClientId={selectedAccountingClientId}
          employeeModalVisible={employeeModalVisible}
          setEmployeeModalVisible={setEmployeeModalVisible}
        />
      </AffixDiv>
    </Content>
  );
}

const AffixDiv = styled.div`
  background-color: white;
  padding-left: 14px;
  padding-right: 14px;
  padding-top: 14px;
  padding-bottom: 8px;
  max-width: 1400px;
  margin: auto;
`;

const ButtonDiv = styled.div`
  text-align: right;
  display: inline-block;
  margin-left: 10px;
`;

export { EmployeesView };
