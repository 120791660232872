import React, { createContext, useEffect, useState } from 'react';
import Cookies from 'universal-cookie';

const authContext = createContext();

const accountingEmails = [
  // this should not be editted going forward. it exists for users who might have old cookies
  'noreplyherofacturas@gmail.com',
  'jarol@herofacturas.com',
  'ernestolyons@gmail.com',
  'salomon.cohen.08@gmail.com',
  'gtesta@gutpanama.com',
  'alex@fonduee.com',
  'javier@fonseca.com',
  'joseralemanc@gmail.com',
  'kalabazapa@gmail.com',
  'maxalfredoga@gmail.com',
  'rocioroldan5@gmail.com',
  'gretelsucre@gmail.com',
  'felix.weng@dalifex.com',
  'milagros.esquivel@dalifex.com',
  'nathalie.rodriguez@dalifex.com',
  'elisayanes@gmail.com',
  'antonio@lagunazo.com',
  'aheilbron@ptmpanama.com',
  'jorge4arias@gmail.com',
  'vale@rpfoodgroup.com',
  'rafael@colrizado.com',
  'a.saenz.u@gmail.com',
  'fguillen@herofacturas.com',
  'invernaderocomplex@gmail.com',
  'contabilidad@celtecgroup.com',
  'd.torres@myenergylatam.com',
  'admin@celtecgroup.com',
  'recepcion@celtecgroup.com',
  'cookingmammapty@gmail.com',
  'guillemarria@gmail.com',
  'miguel@herofacturas.com',
  'gtesta+invernadero@gutpanama.com',
  'rdmm1893@gmail.com',
  'micaelalmanza@gmail.com',
  'autoparteshk24@gmail.com',
  'ivangonzaleza23@gmail.com',
  'gary.martin@quantil.com.co',
  'asistencia@flashcocinas.com',
  'mark@flashcocinas.com',
  'jdguerrac04@gmail.com',
  'jbrawerman@hotmail.com',
  'nutricion@malegiraldo.com',
  'nutricion@dioteiza.com',
  'nutrition@helenrd.com',
  'milaymimopty2@gmail.com',
  'milaymimopty@gmail.com',
];

function useProvideAuth() {
  const cookies = new Cookies();
  const [email, setEmail] = useState(cookies.get('email'));
  const [token, setToken] = useState(cookies.get('token'));
  const [accounts, setAccounts] = useState(
    cookies.get('accounts') ? cookies.get('accounts') : []
  );
  const [adminEmail, setAdminEmail] = useState(cookies.get('adminEmail'));
  const [adminToken, setAdminToken] = useState(cookies.get('adminToken'));
  const [showAccountingFeatures, setShowAccountingFeatures] = useState(
    (cookies.get('showAccountingFeatures') === 'true') ||
      accountingEmails.includes(email)
  );

  if (
    cookies.get('showAccountingFeatures') &&
    cookies.get('showAccountingFeatures') !== 'true'
  ) {
    cookies.remove('showAccountingFeatures', { path: '/' });
  }

  const cookieSetter = (cookieName, cookieValue) => {
    if (cookieValue) {
      cookies.set(cookieName, cookieValue, {
        maxAge: 60 * 60 * 24 * 365,
        path: '/',
      });
    }
  };

  const setAdminStuff = (email, token) => {
    if (
      email === 'noreplyherofacturas@gmail.com' ||
      email === 'jarol@herofacturas.com'
    ) {
      cookieSetter('adminEmail', email);
      cookieSetter('adminToken', token);
      setAdminEmail(email);
      setAdminToken(token);
    }
  };

  const signin = (cb) => {
    const cookies = new Cookies();
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let newEmail = params.get('email');
    let newToken = params.get('token');
    let showAccountingFeatures = params.get('showAccountingFeatures') === 'True';
    if (newEmail && newToken) {
      cookieSetter('email', newEmail);
      setEmail(newEmail);
      cookieSetter('token', newToken);
      setToken(newToken);
      cookieSetter('showAccountingFeatures', showAccountingFeatures);

      setShowAccountingFeatures(showAccountingFeatures);

      setAdminStuff(newEmail, newToken);

      let accountExists = accounts.some(
        (account) => account.email === newEmail
      );
      if (!accountExists) {
        let newAccounts = [
          ...accounts,
          {
            email: newEmail,
            token: newToken,
            showAccountingFeatures: showAccountingFeatures,
          },
        ];
        setAccounts(newAccounts);
        cookieSetter('accounts', JSON.stringify(newAccounts));
      }
    }
    cb();
  };

  const signout = () => {
    const emailToRemove = email;
    const updatedAccounts = accounts.filter(
      (account) => account.email !== emailToRemove
    );
    if (email === emailToRemove) {
      if (updatedAccounts.length > 0) {
        setEmail(updatedAccounts[0].email);
        setToken(updatedAccounts[0].token);
        setShowAccountingFeatures(Boolean(updatedAccounts[0].showAccountingFeatures));
        cookieSetter('email', updatedAccounts[0].email);
        cookieSetter('token', updatedAccounts[0].token);
        cookieSetter(
          'showAccountingFeatures',
          updatedAccounts[0].showAccountingFeatures
        );
      } else {
        setEmail(null);
        setToken(null);
        setShowAccountingFeatures(false);
        cookies.remove('email', { path: '/' });
        cookies.remove('token', { path: '/' });
        cookies.remove('showAccountingFeatures', { path: '/' });
      }
    }
    setAccounts(updatedAccounts);
    if (updatedAccounts.length > 0) {
      cookieSetter('accounts', JSON.stringify(updatedAccounts));
    } else {
      cookies.remove('accounts', { path: '/' });
    }

    // Remove admin cookies and variables if the user is an admin
    const currentAdminEmail = cookies.get('adminEmail');
    const currentAdminToken = cookies.get('adminToken');

    if (email === currentAdminEmail && token === currentAdminToken) {
      cookies.remove('adminEmail', { path: '/' });
      cookies.remove('adminToken', { path: '/' });
      setAdminEmail(null);
      setAdminToken(null);
    }
  };

  const switchAccount = (emailToSwitch) => {
    const accountToSwitch = accounts.find(
      (account) => account.email === emailToSwitch
    );
    if (accountToSwitch) {
      setEmail(accountToSwitch.email);
      setToken(accountToSwitch.token);
      setShowAccountingFeatures(Boolean(accountToSwitch.showAccountingFeatures));
      cookies.remove('showAccountingFeatures', { path: '/' });
      cookieSetter('email', accountToSwitch.email);
      cookieSetter('token', accountToSwitch.token);
      cookieSetter(
        'showAccountingFeatures',
        accountToSwitch.showAccountingFeatures
      );

      setAdminStuff(accountToSwitch.email, accountToSwitch.token);
    }
    window.location.reload();
  };

  const moveAdminEmail = (checked) => {
    if (checked) {
      const adminEmailValue = cookies.get('tempAdminEmail');
      cookieSetter('adminEmail', adminEmailValue);
      setAdminEmail(adminEmailValue);
      cookies.remove('tempAdminEmail', { path: '/' });
    } else {
      cookieSetter('tempAdminEmail', adminEmail);
      cookies.remove('adminEmail', { path: '/' });
      setAdminEmail(false);
    }
  }

  return {
    email,
    token,
    accounts,
    adminEmail,
    adminToken,
    showAccountingFeatures,
    signin,
    signout,
    switchAccount,
    moveAdminEmail,
  };
}

function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export { authContext, ProvideAuth };
