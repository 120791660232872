import React, { useContext, useState, useEffect } from 'react';
import { Button, Layout, Table, message, Modal, Spin, Tag } from 'antd';
import styled from 'styled-components/macro';
import axios from 'axios';
import { authContext } from '../ProvideAuth.js';
import moment from 'moment'; // Import moment for date formatting
import { DownloadOutlined } from '@ant-design/icons'; // Import the Download icon
import * as XLSX from 'xlsx'; // Import XLSX for CSV export
import { FormattedUSD } from './FormattedUSD.js';
import { DateFilterDropdown } from './DateFilterDropdown.js';
import { get_moments_from_month_name } from '../utils.js';

const { Content } = Layout;

function InvuTable(props) {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const auth = useContext(authContext);
  const [pdfUrl, setPdfUrl] = useState(''); // PDF URL state
  const [fetchingPdf, setFetchingPdf] = useState(false); // To show spinner while PDF is fetching

  useEffect(() => {
    const fetchOrders = () => {
      setLoading(true);

      axios({
        method: 'post',
        url: props.API_domain + 'getInvuOrdersForAccountingClient',
        auth: {
          username: auth.email,
          password: auth.token,
        },
        data: {},
      })
        .then((response) => {
          if (response.status === 200 && response.data.invu_orders) {
            setOrders(response.data.invu_orders);
            console.log('Orders:', response.data.invu_orders);
          } else {
            message.error('Failed to fetch orders');
          }
        })
        .catch((error) => {
          console.error('Error fetching orders:', error);
          message.error('An error occurred while fetching orders');
        })
        .finally(() => {
          setLoading(false);
        });
    };

    fetchOrders();
  }, [props.API_domain, auth.email, auth.token]); // Added auth dependencies

  const handleOpenModal = (cufe) => {
    setFetchingPdf(true); // Show loading indicator
    setModalVisible(true);

    axios({
      method: 'post',
      url: props.API_domain + 'getFEpdf', // Update the URL to match your endpoint
      auth: {
        username: auth.email,
        password: auth.token,
      },
      data: {
        cufe, // Send the clicked CUFE to the backend to fetch the PDF link
      },
    })
      .then((response) => {
        if (response.status === 200) {
          setPdfUrl(response.data.fe_pdf_url); // Assuming response.data.fe_pdf_url contains the PDF URL
        } else {
          message.error('Failed to fetch PDF link');
        }
      })
      .catch((error) => {
        console.error('Error fetching PDF link:', error);
        message.error('Error consiguiendo el enlace del PDF.');
      })
      .finally(() => {
        setFetchingPdf(false); // Stop showing spinner after fetch
      });
  };

  const handleCloseModal = () => {
    setModalVisible(false);
    setPdfUrl(''); // Reset the PDF URL
  };

  // Function to export table data to CSV
  const exportToCSV = () => {
    if (!orders || orders.length === 0) {
      message.warning('No hay datos para exportar.');
      return;
    }

    // Define headers based on your current table columns
    const headers = [
      'Fecha de Cierre',
      'Número de Factura',
      'Forma de Pago',
      'Impuesto',
      'Total',
    ];

    // Map data to rows
    const data = orders.map((order) => {
      const row = [];

      // Fecha de Cierre
      row.push(
        order.fecha_cierre_date
          ? moment(order.fecha_cierre_date).format('YYYY-MM-DD')
          : ''
      );

      // Número de Factura
      row.push(order.numero_factura ? order.numero_factura : '');

      // Forma de Pago
      row.push(order.payments.length > 0 ? order.payments[0].payment_type : '');

      // Impuesto
      row.push(
        order.tax !== null && order.tax !== undefined
          ? order.tax.toFixed(2)
          : '0.00'
      );

      // Total
      row.push(
        order.total !== null && order.total !== undefined
          ? order.total.toFixed(2)
          : '0.00'
      );

      return row;
    });

    // Combine headers and data
    const csvData = [headers, ...data];

    // Create a worksheet
    const ws = XLSX.utils.aoa_to_sheet(csvData);

    // Create a workbook and append the worksheet
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Orders');

    // Generate a binary string representation of the workbook
    const wbout = XLSX.write(wb, { bookType: 'csv', type: 'array' });

    // Create a Blob from the binary string
    const blob = new Blob([wbout], { type: 'text/csv;charset=utf-8;' });

    // Create a link to download the Blob
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;

    // Name the file with current date
    const currentDate = moment().format('YYYYMMDD_HHmmss');
    a.download = `invu_orders_${currentDate}.csv`;
    a.click();
    URL.revokeObjectURL(url);

    message.success('Tabla exportada exitosamente.');
  };

  const columns = [
    {
      title: 'Fecha de Cierre',
      dataIndex: 'fecha_cierre_date',
      key: 'fecha_cierre_date',
      width: 240, // Adjust width as needed
      sorter: (a, b) =>
        moment(a.fecha_cierre_date).unix() - moment(b.fecha_cierre_date).unix(),
      render: (text) => moment(text).format('YYYY-MM-DD'), // Format date to only show the date
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <DateFilterDropdown
          selectedKeys={selectedKeys}
          setSelectedKeys={setSelectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
        />
      ),
      onFilter: (value, record) => {
        const cierreDate = moment(record.fecha_cierre_date);

        if (Array.isArray(value) && value.length === 2) {
          const [start, end] = value;
          return cierreDate.isBetween(start, end, 'day', '[]');
        } else if (typeof value === 'string') {
          const filterDates = get_moments_from_month_name(value); // Ensure this function is available
          return cierreDate.isBetween(
            filterDates[0],
            filterDates[1],
            'day',
            '[]'
          );
        }

        return false;
      },
    },
    {
      title: 'Número de Factura',
      dataIndex: 'numero_factura',
      key: 'numero_factura',
      render: (text) => (
        <Button type='link' onClick={() => handleOpenModal(text)}>
          {text}
        </Button>
      ),
    },
    {
      title: 'Forma de pago',
      dataIndex: 'payments',
      key: 'payments',
      render: (payments, record) => (
        <span style={{ textTransform: 'capitalize' }}>
          {payments.length > 0
            ? payments[0].payment_type
                .toLowerCase()
                .replace(/\b\w/g, (c) => c.toUpperCase())
            : ''}
          {record.pagada !== 'Cerrada' && (
            <Tag color='red' style={{ marginLeft: 4 }}>
              {record.pagada}
            </Tag>
          )}
        </span>
      ),
      filters: [
        { text: 'Pedidos Ya', value: 'Pedidos Ya' },
        { text: 'Tarjeta', value: 'Tarjeta' },
        { text: 'Efectivo', value: 'Efectivo' },
        { text: 'Yappy', value: 'YAPPY' },
      ],
      onFilter: (value, record) =>
        record.payments.some((payment) => payment.payment_type === value),
    },
    {
      title: 'Impuesto',
      dataIndex: 'tax',
      key: 'tax',
      render: (value) => <FormattedUSD total={value.toFixed(2)} />, // Formatting as currency
      sorter: (a, b) => a.tax - b.tax, // Sorting by tax
      align: 'right', // Align the content to the right
    },
    {
      title: 'Total',
      dataIndex: 'total',
      key: 'total',
      render: (value) => <FormattedUSD total={value.toFixed(2)} />, // Formatting as currency
      sorter: (a, b) => a.total - b.total, // Sorting by total
      align: 'right', // Align the content to the right
    },
  ];

  return (
    <Content
      style={{
        margin: '0 0 0',
        overflow: 'initial',
        borderRight: 'solid rgb(235,235,235) 1px',
        borderTop: 'solid rgb(235,235,235) 1px',
      }}
    >
      <AffixDiv style={{ maxWidth: '1400px', margin: 'auto' }}>
        {/* Add the Export Button here */}
        <div style={{ textAlign: 'right' }}>
          <Button
            type='default'
            icon={<DownloadOutlined />}
            onClick={exportToCSV}
            style={{ marginBottom: '16px' }} // Add some spacing below the button
          >
            Excel
          </Button>
        </div>
        <Table
          columns={columns}
          dataSource={orders} // Use the full orders data; filtering is handled by the table
          rowKey='id'
          loading={loading}
          pagination={{ pageSize: 100 }}
        />
        <Modal
          title='Factura XML Details'
          visible={modalVisible}
          onCancel={handleCloseModal}
          footer={null}
          width={800}
        >
          {fetchingPdf ? (
            <Spin tip='Cargando PDF...' /> // Show spinner while loading
          ) : pdfUrl !== '' ? (
            <iframe
              src={pdfUrl}
              width='100%'
              height='600px'
              title='Factura PDF'
              frameBorder='0'
            />
          ) : (
            <p>No se encontró el PDF.</p>
          )}
        </Modal>
      </AffixDiv>
    </Content>
  );
}

const AffixDiv = styled.div`
  background-color: white;
  padding-left: 14px;
  padding-right: 14px;
  padding-top: 14px;
  padding-bottom: 8px;
`;

export { InvuTable };
