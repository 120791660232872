// AccountingTransactionsTable.js

import React, { useState, useEffect } from 'react';
import { Table, Modal, Button, message, Popconfirm } from 'antd';
import axios from 'axios';
import { DeleteOutlined } from '@ant-design/icons';
import AccountingAccountSelect from './AccountingAccountSelect'; // Import the component

function AccountingTransactionsTable({ API_domain, accountingClientCompany, auth }) {
  const [transactions, setTransactions] = useState([]);
  const [entries, setEntries] = useState([]);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);

  // Fetch transactions from the backend
  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        const response = await axios.post(
          `${API_domain}getAccountingTransactions`,
          {
            client_id: accountingClientCompany.accounting_client_id,
          },
          {
            auth: {
              username: auth.email,
              password: auth.token,
            },
          }
        );
        setTransactions(response.data.transactions);
      } catch (error) {
        console.error('Error fetching transactions:', error);
        message.error('Error al obtener las transacciones');
      }
    };

    fetchTransactions();
  }, [API_domain, accountingClientCompany, auth]);

  // Fetch entries for a selected transaction
  const fetchEntries = async (transaction_id) => {
    try {
      const response = await axios.post(
        `${API_domain}getTransactionEntries`,
        {
          transaction_id,
        },
        {
          auth: {
            username: auth.email,
            password: auth.token,
          },
        }
      );
      setEntries(response.data.entries);
    } catch (error) {
      console.error('Error fetching entries:', error);
      message.error('Error al obtener las entradas');
    }
  };

  // Handle row click to open modal
  const handleRowClick = (record) => {
    setSelectedTransaction(record);
    fetchEntries(record.transaction_id);
    setIsModalVisible(true);
  };

  // Handle transaction deletion
  const handleDeleteTransaction = async (transaction_id) => {
    try {
      await axios.post(
        `${API_domain}deleteTransaction`,
        {
          transaction_id,
        },
        {
          auth: {
            username: auth.email,
            password: auth.token,
          },
        }
      );
      message.success('Transacción eliminada exitosamente');
      // Refresh transactions list
      setTransactions((prev) =>
        prev.filter((t) => t.transaction_id !== transaction_id)
      );
    } catch (error) {
      console.error('Error deleting transaction:', error);
      message.error('Error al eliminar la transacción');
    }
  };

  // Handle entry account update
  const handleAccountChange = (entry_id, new_account_id) => {
    setEntries((prevEntries) =>
      prevEntries.map((entry) =>
        entry.entry_id === entry_id
          ? { ...entry, account_id: new_account_id }
          : entry
      )
    );
  };

  // Save updated entries
  const handleSaveEntries = async () => {
    try {
      await axios.post(
        `${API_domain}updateEntries`,
        {
          entries,
        },
        {
          auth: {
            username: auth.email,
            password: auth.token,
          },
        }
      );
      message.success('Entradas actualizadas exitosamente');
      setIsModalVisible(false);
    } catch (error) {
      console.error('Error updating entries:', error);
      message.error('Error al actualizar las entradas');
    }
  };

  const transactionColumns = [
    {
      title: 'Descripción',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Fecha de Transacción',
      dataIndex: 'transaction_date',
      key: 'transaction_date',
    },
    {
      title: 'Fecha de Creación',
      dataIndex: 'created_time',
      key: 'created_time',
    },
    {
      title: 'Zoho Journal ID',
      dataIndex: 'zoho_journal_id',
      key: 'zoho_journal_id',
    },
    {
      title: 'Acciones',
      key: 'actions',
      render: (_, record) => (
        <Popconfirm
          title='¿Seguro que deseas eliminar esta transacción?'
          onConfirm={() => handleDeleteTransaction(record.transaction_id)}
          okText='Sí'
          cancelText='No'
        >
          <Button type='danger' icon={<DeleteOutlined />} />
        </Popconfirm>
      ),
    },
  ];

  const entryColumns = [
    {
      title: 'Entry ID',
      dataIndex: 'entry_id',
      key: 'entry_id',
    },
    {
      title: 'Cuenta',
      dataIndex: 'account_id',
      key: 'account_id',
      render: (text, record) => (
        <AccountingAccountSelect
          value={record.account_id}
          onChange={(value) => handleAccountChange(record.entry_id, value)}
          clientId={accountingClientCompany.accounting_client_id}
          API_domain={API_domain}
          auth={auth}
        />
      ),
    },
    {
      title: 'Monto',
      dataIndex: 'amount',
      key: 'amount',
    },
    {
      title: 'Tipo de Entrada',
      dataIndex: 'entry_type',
      key: 'entry_type',
    },
  ];

  return (
    <>
      <Table
        dataSource={transactions}
        columns={transactionColumns}
        rowKey='transaction_id'
        onRow={(record) => ({
          onClick: () => handleRowClick(record),
        })}
      />

      <Modal
        title={`Entradas de la Transacción ${selectedTransaction?.description}`}
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        onOk={handleSaveEntries}
        width={800}
      >
        <Table
          dataSource={entries}
          columns={entryColumns}
          rowKey='entry_id'
          pagination={false}
        />
      </Modal>
    </>
  );
}

export { AccountingTransactionsTable };
